import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { Theme as theme, StatusBadge, InformationIconSmall, MiniTooltip } from '@spoiler-alert/ui-library';
import moment from 'moment';
import { customerParticipationStatus, featureFlags } from '../../enums';
import checkFeature from '../../helpers/check-feature-flag';

const useStyles = createUseStyles({
  scroll: {
    overflowY: 'auto',
    // 100vh - cps content above table - space between top of vh and cps tooltip - margin below table - desired space between bottom of screen - table header
    maxHeight: 'calc(100vh - 288px - 104px - 24px - 24px - 33px)',
  },
  cell: {
    height: 20,
    fontFamily: 'Roboto',
    fontSize: 14,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.43,
    letterSpacing: '-0.08px',
    textAlign: 'left',
    color: theme.greyDark,
  },
  table: {
    width: '100%',
    maxHeight: '100%',
    borderSpacing: '0px',
    borderCollapse: 'separate',
    overflow: 'auto',
  },
  th: {
    extend: 'cell',
    padding: '6.5px 12px',
    backgroundColor: theme.grey10,
    fontWeight: 500,
    position: 'sticky',
    top: 0,
  },
  th1: (buyerEmailReminderFeature) => ({
    extend: 'th',
    borderRadius: '8px 0px 0px 0px',
    width: buyerEmailReminderFeature ? '33%' : '55%',
  }),
  th2: (buyerEmailReminderFeature) => ({
    extend: 'th',
    borderRadius: '0px 0px 0px 0px',
    width: buyerEmailReminderFeature ? '33%' : '45%',
    textAlign: buyerEmailReminderFeature ? 'center' : 'unset',
  }),
  th3: {
    extend: 'th',
    borderRadius: '0px 8px 0px 0px',
    width: '33%',
    textAlign: 'end',
  },
  tr: {
    '&:hover': {
      backgroundColor: theme.grey5,
    },
  },
  customerName: (buyerEmailReminderFeature) => ({
    extend: 'cell',
    padding: '10px 12px',
    fontWeight: 'normal',
    width: buyerEmailReminderFeature ? '40%' : '55%',
  }),
  customerStatus: (buyerEmailReminderFeature) => ({
    padding: '8px 5px',
    height: 20,
    width: buyerEmailReminderFeature ? '30%' : '45%',
  }),
  customerReminderStatus: {
    extend: 'cell',
    padding: '10px 12px',
    fontWeight: 'normal',
    width: '30%',
    textAlign: 'end',
  },
  noMatches: {
    extend: 'cell',
    padding: '24px 0px',
    textAlign: 'center',
  },
  tooltipText: {
    width: 175,
    height: 35,
    paddingLeft: 8,
    fontWeight: 'normal',
    textAlign: 'left',
  },
  infoIcon: {
    height: 18,
    width: 18,
    marginRight: 8,
    fill: theme.alertSuccessColor,
    cursor: 'pointer',
  },
  reminderContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

const CustomerParticipationSummaryTable = ({ customerInfo }) => {
  const buyerEmailReminderFeature = checkFeature(featureFlags.buyerEmailReminder);
  const classes = useStyles(buyerEmailReminderFeature);
  const sortedCustomerInfo = useMemo(() => {
    return customerInfo.sort((a, b) => {
      if (a.status === b.status) return a.buyerSiteName.localeCompare(b.buyerSiteName);
      if (a.status === customerParticipationStatus.OFFERS_RECEIVED) return -1;
      return 1;
    });
  }, [customerInfo]);

  const tooltipText = (timestamp) => {
    const date = moment(timestamp).format('MMMM DD');
    const time = moment(timestamp).format('hh:mm A');
    const timezone = new Date(timestamp).toLocaleString('en-US', { timeZoneName: 'short' }).split(' ')[3];
    return `Scheduled for:\n ${date} at ${time} ${timezone}`;
  };

  return (
    <div>
      <table className={classes.table}>
        <thead>
          <tr>
            <th className={classes.th1}>Customer</th>
            <th className={classes.th2}>Status</th>
            {buyerEmailReminderFeature && <th className={classes.th3}>Reminder Email</th>}
          </tr>
        </thead>
      </table>
      <div className={classes.scroll}>
        <table className={classes.table}>
          <tbody>
            {customerInfo?.length > 0 ? (
              sortedCustomerInfo.map((customer) => (
                <tr key={customer.buyerSiteName} className={classes.tr}>
                  <td className={classes.customerName}>{customer.buyerSiteName}</td>
                  <td className={classes.customerStatus}>
                    <StatusBadge status={customer.status} />
                  </td>
                  {buyerEmailReminderFeature && (
                    <td className={classes.customerReminderStatus}>
                      <div className={classes.reminderContainer}>
                        {customer.reminderTimestamp && (
                          <MiniTooltip
                            position="left"
                            text={
                              <div className={classes.tooltipText}>
                                <span style={{ width: '100%', whiteSpace: 'pre-line' }}>{tooltipText(customer.reminderTimestamp)}</span>
                              </div>
                            }
                          >
                            <InformationIconSmall className={classes.infoIcon} />
                          </MiniTooltip>
                        )}
                        {customer.reminderStatus}
                      </div>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" className={classes.noMatches}>
                  No customers match your search.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

CustomerParticipationSummaryTable.propTypes = {
  customerInfo: PropTypes.arrayOf(PropTypes.object),
};

export default CustomerParticipationSummaryTable;
