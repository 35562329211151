import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import {
  Theme as theme,
  AlertService,
  EmptyStateIcon,
  NoneSelectedIcon,
  Modal,
  Button,
  ModalContent,
  ModalFooter,
  GraphIncreaseIcon,
  LoadingLine,
  LoadingSmall,
  WandIcon,
} from '@spoiler-alert/ui-library';
import accounting from 'accounting';
import { OfferListingsByInventoryQuery } from '../../graphql/queries';
import OfferCardV2 from './offer-card-v2';
import MarketplaceFade from '../../transitions/marketplace-fade';
import checkFeature from '../../helpers/check-feature-flag';
import featureFlags from '../../enums/feature-flags';
import { offerlistingStatuses } from '../../enums';
import { AwardSuggestedOfferListingsWithUIValidation } from '../../graphql/mutations';
import { OfferComparisonStrings } from '../../string-resources';
import trucklaneChannelStatus from '../../enums/trucklane-channel-status';

const useStyles = createUseStyles({
  offerWrapper: (props) => ({
    minWidth: props.wrapperMinWidth,
    position: 'sticky',
    top: props.stickyOffset,
    flex: '0 0 459px',
  }),
  offerContainer: (props) => ({
    zIndex: 10,
    background: 'white',
    position: 'sticky',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    top: props.stickyOffset,
    bottom: 50,
    width: '100%',
    minWidth: 459,
  }),
  offerDescription: {
    marginTop: '-1rem',
    marginBottom: 10,
  },
  offerHeading: {
    fontWeight: 400,
  },
  noOffersContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingTop: '100px',
  },
  noOffersWrapper: {
    height: '100%',
  },
  noInventoryContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 338px)',
  },
  noOffers: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: '1.2em',
    color: theme.text,
    fontWeight: 500,
    width: 163,
  },
  offerTransitionWrapper: (props) => ({
    height: props.offerTransitionWrapperHeight,
    padding: props.offerTransitionWrapperPadding,
    overflowY: 'auto',
    boxShadow: '0px 10px 10px 0px rgb(255 255 255)',
  }),
  offerHeadingWrapper: (props) => ({
    zIndex: 1,
    padding: props.offerHeadingPadding,
    fontSize: '14px',
    minHeight: props.offerHeadingHeight,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: '10px',
    boxShadow: `inset 0 -1px 0 0 ${theme.grey30}`,
    fontWeight: 500,
  }),
  descriptionHeader: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  loadingLineWrapper: {
    position: 'relative',
  },
  inventoryDescription: (props) => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    maxWidth: props.inventoryDescriptionMaxWidth,
    fontSize: props.inventoryDescriptionFontSize,
    fontWeight: props.inventoryDescriptionFontWeight,
    marginBottom: props.inventoryDescriptionMarginBottom,
  }),
  inventoryDescritionTruncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    margin: '0px',
  },
  noInventoryHeader: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: '1.2em',
    color: theme.text,
    fontWeight: 500,
    width: 163,
    margin: '1em 0 0 0',
  },
  noInventory: {
    textAlign: 'center',
    alignSelf: 'center',
    fontSize: '14px',
    color: theme.text,
    width: 260,
  },
  historicalOffers: {
    fontSize: 14,
    fontWeight: 400,
  },
  header: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: '100%',
  },
  historicalOffersContainer: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    width: 125,
    cursor: 'pointer',
    color: theme.teal,
    stroke: theme.teal,
    '&:hover': {
      color: theme.tealDark,
      stroke: theme.tealDark,
    },
    marginLeft: '24px',
  },
  loadingContainer: {
    height: 705,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  itemHeader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  totalSuggestedAwards: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  totalSuggestedAwardsText: {
    fontSize: '12px',
    fontWeight: '500',
    height: '16px',
  },
  totalSuggestedAwardsResult: {
    fontSize: '16px',
    height: '22px',
  },
});

const OfferListings = ({
  inventoryId,
  description,
  availableQuantity,
  user,
  fullstoryTag,
  stickyOffset,
  trucklaneChannel,
  recalculationRequired = false,
  recalculating = false,
  isBulkAwarding = false,
}) => {
  const styles = {
    offerHeadingPadding: '12px',
    offerHeadingHeight: '98px',
    offerTransitionWrapperHeight: 'calc(100vh - 195px)',
    offerTransitionWrapperPadding: recalculationRequired ? '8px 8px 175px 8px' : '8px',
    inventoryDescriptionFontSize: '16px',
    inventoryDescriptionFontWeight: 'normal',
    inventoryDescriptionMarginBottom: '12px',
    inventoryDescriptionMaxWidth: checkFeature(featureFlags.historicalOffers) ? '285px' : null,
    stickyOffset: stickyOffset || 0,
  };
  const classes = useStyles(styles);
  const {
    data,
    loading: offerListingsLoading,
    refetch,
    error,
  } = useQuery(OfferListingsByInventoryQuery, {
    variables: { inventoryIds: [inventoryId] },
    skip: !inventoryId,
    fetchPolicy: 'cache-and-network',
  });
  const [awardSuggestedOfferListingsWithUIValidation, { loading: awardingSuggestionsLoading }] = useMutation(
    AwardSuggestedOfferListingsWithUIValidation
  );
  const loading = offerListingsLoading || recalculating;

  useEffect(() => {
    if (trucklaneChannel?.status === trucklaneChannelStatus.VALID) refetch();
  }, [trucklaneChannel?.status]);

  const [disabled, setDisabled] = useState(false);
  const [showOfferVisualizationModal, setShowOfferVisualizationModal] = useState(false);
  const [iframeLoading, setIframeLoading] = useState(true);

  const offerListings = useMemo(() => {
    const pricePerCase = (ol) => ol.totalPrice / ol.quantity;
    if (data && !loading && !error) {
      return [...data.offerListingsByInventoryQuery.offerListings].sort((a, b) => {
        if (a.status === 'AWARDED' && b.status !== 'AWARDED') return -1;
        if (a.status !== 'AWARDED' && b.status === 'AWARDED') return 1;
        if (a.status === 'ACTIVE' && b.status !== 'ACTIVE') return -1;
        if (a.status !== 'ACTIVE' && b.status === 'ACTIVE') return 1;
        if (pricePerCase(a) > pricePerCase(b)) return -1;
        if (pricePerCase(a) < pricePerCase(b)) return 1;
        return 0;
      });
    }
    return [];
  }, [data?.offerListingsByInventoryQuery.offerListings]);

  const renderOfferListing = (offerListing) => {
    return (
      <MarketplaceFade key={`.fade-${offerListing._id}`} exit={false} timeout={200}>
        <div id={`offer-${offerListing._id}`}>
          <OfferCardV2
            offerInfo={offerListing}
            disabled={disabled}
            setOfferCardsEnabledState={setDisabled}
            key={offerListing._id}
            inventoryId={inventoryId}
            user={user}
            fullstoryTag={fullstoryTag}
            showSuggestions={!recalculationRequired}
          />
        </div>
      </MarketplaceFade>
    );
  };

  const noListingsV2 = () => {
    return inventoryId && offerListings.length === 0 && data && !loading ? (
      <div className={classes.noOffersContainer}>
        <EmptyStateIcon />
        <p className={classes.noOffers}>The selected inventory has no offers</p>
      </div>
    ) : null;
  };

  const noInventory = () => {
    return !inventoryId ? (
      <div className={classes.noInventoryContainer}>
        <NoneSelectedIcon />
        <p className={classes.noInventoryHeader}>No Inventory Selected</p>
        <p className={classes.noInventory}>Select inventory on the left to view offers</p>
      </div>
    ) : null;
  };

  const noAvailibleQty = () => {
    return availableQuantity === 0;
  };
  const suggestions = () => {
    if (data)
      return [...data?.offerListingsByInventoryQuery?.offerListings].filter(
        (ol) => ol.suggestions?.award?.suggested && ol.status !== offerlistingStatuses.AWARDED
      );
    return [];
  };
  const noSuggestedAwards = () => {
    return !suggestions().length;
  };

  const totalSuggestedAwards = () => {
    if (data && !loading && !error) {
      if (recalculationRequired) return <span>Recalculation Required</span>;
      if (noAvailibleQty()) return <span>All Inventory Awarded</span>;
      if (noSuggestedAwards()) return <span>No Suggested Awards</span>;
      return (
        <div className={classes.totalSuggestedAwardsResult}>
          {accounting.formatMoney(
            suggestions().reduce((totalSuggestedCost, ol) => {
              if (ol.quantity === 0) return totalSuggestedCost;
              const pricePerCase = ol.totalPrice / ol.quantity;
              return totalSuggestedCost + ol.suggestions.award.quantity * pricePerCase;
            }, 0)
          )}
        </div>
      );
    }
    return null;
  };

  const awardSuggestedOffers = () => {
    awardSuggestedOfferListingsWithUIValidation({
      variables: {
        offerListingIds: suggestions().map((ol) => ol._id),
        trucklaneGroupChannelId: trucklaneChannel._id,
        trucklaneGroupChannelUpdatedAt: trucklaneChannel.updatedAt,
      },
    })
      .then((resp) => {
        if (resp.data?.awardSuggestedOfferListingsWithUIValidation?.errors?.length) throw Error();
      })
      .catch(() => AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{OfferComparisonStrings.awardingError}</span> }));
  };

  const dataViz = () => {
    return (
      checkFeature(featureFlags.historicalOffers) &&
      description &&
      !loading && (
        <div className={classes.historicalOffersContainer} onClick={() => setShowOfferVisualizationModal(true)}>
          <GraphIncreaseIcon />
          <span className={classes.historicalOffers}>Historical Offers</span>
        </div>
      )
    );
  };

  const itemHeader = () => {
    return (
      <div className={classes.itemHeader}>
        <div className={classes.descriptionHeader}>
          <div data-testid="inventory-description" className={classes.inventoryDescription}>
            <p className={classes.inventoryDescritionTruncate} data-testid="description-text">
              {description && !loading ? description : null}
            </p>
          </div>
          {dataViz()}
        </div>
        {description && !loading && (
          <div className={classes.totalSuggestedAwards}>
            <div>
              <div className={classes.totalSuggestedAwardsText}>TOTAL SUGGESTED AWARDS</div>
              {totalSuggestedAwards()}
            </div>
            <Button
              style={{ alignSelf: 'center', height: '36px' }}
              icon={WandIcon}
              disabled={recalculationRequired || noAvailibleQty() || noSuggestedAwards()}
              onClick={awardSuggestedOffers}
              loading={awardingSuggestionsLoading || isBulkAwarding}
              loadingText="Awarding Suggestions..."
            >
              Award Suggestions
            </Button>
          </div>
        )}
      </div>
    );
  };

  const onHide = () => {
    setIframeLoading(true);
    setShowOfferVisualizationModal(false);
  };

  const lookerDashboard = () => {
    if (data && !loading && !error)
      return (
        <>
          <Modal onHide={onHide} open={showOfferVisualizationModal} closeOnEsc closeOnOutsideClick staticHeight={true}>
            <ModalContent className={classes.modalContent}>
              <iframe
                src={data.offerListingsByInventoryQuery.offerVisualizationUrl}
                style={{ height: 735, width: 820, border: 'none', display: (!iframeLoading && 'block') || 'none' }}
                onLoad={() => setIframeLoading(false)}
              />
              {iframeLoading && (
                <div className={classes.loadingContainer}>
                  <LoadingSmall />
                </div>
              )}
            </ModalContent>
            <ModalFooter>
              <Button type="button" onClick={onHide} secondary>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </>
      );
    return null;
  };

  const loadingMessage = () => {
    return (
      loading && (
        <div className={classes.noOffersContainer}>
          <p className={classes.noOffers}>LOADING OFFERS...</p>
        </div>
      )
    );
  };

  return (
    <div className={classes.offerWrapper}>
      <div className={classes.offerContainer} data-testid="offer-container">
        <div className={classes.loadingLineWrapper}>
          <LoadingLine loading={loading} position="top" />
          <LoadingLine loading={loading} position="bottom" />
          <div className={classes.offerHeadingWrapper}>{itemHeader()}</div>
        </div>
        <div className={offerListings.length ? classes.offerTransitionWrapper : classes.noOffersWrapper} data-testid="offer-transition-wrapper">
          {loadingMessage()}
          {lookerDashboard()}
          {offerListings.map(renderOfferListing)}
          {noListingsV2()}
          {noInventory()}
        </div>
      </div>
    </div>
  );
};

OfferListings.propTypes = {
  data: PropTypes.object,
  inventoryId: PropTypes.string,
  description: PropTypes.string,
  availableQuantity: PropTypes.number,
  user: PropTypes.object,
  fullstoryTag: PropTypes.string,
  stickyOffset: PropTypes.number,
  trucklaneChannel: PropTypes.object,
  recalculationRequired: PropTypes.bool,
  recalculating: PropTypes.bool,
  isBulkAwarding: PropTypes.bool,
};

export default OfferListings;
