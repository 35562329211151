/* eslint-disable max-classes-per-file */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { NoTemplateRoute, UnAuthedRoute, AuthedRoute, RedirectRoute, SupportRoute } from './route-handlers';
import routePaths from './route-paths';
import routeGuards from './route-guards';
import NotFoundLayout from './layouts/not-found-layout';
import { SignIn, SSOSignIn, SSOTokenExchange } from './pages/login';
import ForgotPassword from './pages/login/forgot-password';
import Unverified from './pages/login/unverified';
import RegisterInvitee from './pages/login/register-invitee';
import Logout from './pages/logout';
import ChangePassword from './pages/login/change-password';
import TaxDashboard from './pages/tax-dashboard/tax-dashboard';
import TaxAbout from './pages/tax-dashboard/tax-about';
import TaxRecipt from './pages/tax-receipt';
import ActivityPosts from './pages/activity/my-posts/my-posts';
import ActivityClaims from './pages/activity/my-claims/my-claims';
import PendingVerifications from './pages/activity/my-verifications/my-verifications';
import LogTransaction from './pages/activity/forms/log-transaction';
import UserManagement from './pages/users/user-management';
import ItemCatalog from './pages/items/catalog';
import ItemAbout from './pages/items/item-about';
import ItemHistory from './pages/items/item-history';
import SiteSettings from './pages/site-settings/site-settings';
import Staging from './pages/staging/staging';
import { Inventory } from './pages/inventory';
import { OfferReview, OfferLogisticsReview } from './pages/offers';
import OfferComparison from './pages/offer-comparison';
import Post from './pages/post';
import SetResetPassword from './pages/login/set-reset-password';
import BulkItemImport from './pages/bulk-item-import/bulk-item-import';
import SiteList from './pages/admin/site-list';
import AddSite from './pages/admin/add-site';
import { BulkActivityImport } from './pages/bulk-activity-import';
import EditTransaction from './pages/activity/forms/edit-transaction';
import ProxyView from './pages/proxy';
import TransactionHistory from './pages/transaction-history/transaction-history'; // Why is the named export used instead of the default?
import Transactions from './pages/transactions/transactions';
import { Negotiations, NegotiationsDetail } from './pages/negotiations';
import LookerDashboard from './pages/looker-dashboard';
import ReportsDashboard from './pages/reports-dashboard';
import { CatalogDetail } from './pages/staging';
import { EnterpriseDashboard } from './pages';
import { CustomerProfileDetail, CustomerProfiles } from './pages/customers';
import OrgSettings from './pages/org-settings/org-settings';
import Trucklane from './pages/trucklane/trucklane';
import TabSwitcher from './components/tab-switcher/tab-switcher';
import featureFlags from './enums/feature-flags';
import FeatureFlagSwitcher from './feature-flag-switcher';
import HolisticsDashboard from './pages/holistics-dashboard';
import { DASHBOARD_LOCATIONS } from './enums/holistics-dashboard-enums';
import OfferReviewV2 from './pages/offer-review/offer-reviewV2';

@withRouter
class RouteSwitch extends Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    user: PropTypes.object,
  };

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged(this.props.location);
    }
  }

  onRouteChanged(location) {
    ReactGA.pageview(`${location.pathname}${location.search}${location.hash}`);
  }

  render() {
    const { user } = this.props;
    return (
      <Switch>
        <UnAuthedRoute exact path={routePaths.signIn} component={SignIn} />
        <UnAuthedRoute exact path={`${routePaths.ssoSignIn}`} component={SSOSignIn} />
        <UnAuthedRoute exact path={`${routePaths.ssoSignIn}/:path`} component={SSOSignIn} />
        <NoTemplateRoute exact path={`${routePaths.ssoTokenExchange}`} component={SSOTokenExchange} />
        <UnAuthedRoute exact path={routePaths.forgotPassword} component={ForgotPassword} />
        <UnAuthedRoute exact path={`${routePaths.resetPassword}:token`} component={SetResetPassword} />
        <UnAuthedRoute exact path={routePaths.unverified} component={Unverified} />
        <UnAuthedRoute exact path={`${routePaths.registerInvitee}:token`} component={RegisterInvitee} />
        <SupportRoute exact path={routePaths.supportSSO} user={user} />

        <AuthedRoute
          path={routePaths.platformDashboard}
          component={FeatureFlagSwitcher}
          featureFlag={featureFlags.holisticsDashboard}
          isOn={HolisticsDashboard}
          locationDefault={DASHBOARD_LOCATIONS.DASHBOARD_TAB}
          isOff={LookerDashboard}
          user={user}
          basePath={`${routePaths.platformDashboard}/`}
          guard={routeGuards.lookerDashboard}
        />
        <AuthedRoute
          exact
          path={routePaths.home}
          component={FeatureFlagSwitcher}
          featureFlag={featureFlags.holisticsDashboard}
          isOn={HolisticsDashboard}
          locationDefault={DASHBOARD_LOCATIONS.DASHBOARD_TAB}
          isOff={LookerDashboard}
          user={user}
          basePath={routePaths.home}
          guard={routeGuards.lookerDashboard}
        />
        <AuthedRoute
          path={`${routePaths.home}r/`}
          component={FeatureFlagSwitcher}
          featureFlag={featureFlags.holisticsDashboard}
          isOn={HolisticsDashboard}
          locationDefault={DASHBOARD_LOCATIONS.DASHBOARD_TAB}
          isOff={LookerDashboard}
          user={user}
          basePath={routePaths.home}
          guard={routeGuards.lookerDashboard}
        />
        <AuthedRoute
          path={routePaths.reportsDashboard}
          component={FeatureFlagSwitcher}
          featureFlag={featureFlags.holisticsDashboard}
          isOn={HolisticsDashboard}
          locationDefault={DASHBOARD_LOCATIONS.REPORTS_TAB}
          isOff={ReportsDashboard}
          user={user}
          basePath={`${routePaths.reportsDashboard}/`}
          guard={routeGuards.lookerDashboard}
        />

        <RedirectRoute exact path={routePaths.dashboard} redirectPath={routePaths.home} />
        <RedirectRoute exact path={routePaths.marketplace} redirectPath={routePaths.home} />
        <AuthedRoute
          exact
          path={routePaths.enterpriseDashboard}
          component={EnterpriseDashboard}
          user={user}
          guard={routeGuards.enterpriseDashboard}
        />
        <AuthedRoute exact path={routePaths.changePassword} component={ChangePassword} user={user} />
        <AuthedRoute exact path={routePaths.taxDashboard} component={TaxDashboard} user={user} guard={routeGuards.tax} />
        <AuthedRoute exact path={routePaths.taxAbout} component={TaxAbout} user={user} guard={routeGuards.tax} />
        <AuthedRoute exact path={`${routePaths.taxReceipt}:id`} component={TaxRecipt} user={user} guard={routeGuards.tax} />
        <AuthedRoute exact path={routePaths.logTransaction} component={LogTransaction} user={user} />
        <AuthedRoute exact path={`${routePaths.editTransaction}:id`} component={EditTransaction} user={user} />
        <AuthedRoute exact path={`${routePaths.post}:id`} component={Post} user={user} guard={routeGuards.post} />
        <AuthedRoute exact path={routePaths.myPosts} component={ActivityPosts} user={user} guard={routeGuards.posts} />
        <AuthedRoute exact path={routePaths.myClaims} component={ActivityClaims} user={user} guard={routeGuards.claims} />
        <AuthedRoute exact path={routePaths.myVerifications} component={PendingVerifications} user={user} guard={routeGuards.claims} />
        <RedirectRoute exact path={routePaths.pendingVerifications} redirectPath={routePaths.myVerifications} />
        <AuthedRoute exact path={routePaths.userManagement} component={UserManagement} user={user} guard={routeGuards.userManagement} />
        <AuthedRoute exact path={`${routePaths.proxy}:type/:id/:filename`} component={ProxyView} user={user} />
        <AuthedRoute exact path={routePaths.items} component={ItemCatalog} user={user} guard={routeGuards.items} />
        <AuthedRoute exact path={routePaths.itemAbout} component={ItemAbout} user={user} />
        <AuthedRoute exact path={routePaths.itemHistory} component={ItemHistory} user={user} guard={routeGuards.items} />
        <AuthedRoute exact path={`${routePaths.itemHistoryDetail}:id/:itemName`} component={ItemHistory} user={user} />
        <AuthedRoute exact path={routePaths.itemImport} component={BulkItemImport} user={user} />
        <AuthedRoute exact path={routePaths.siteSettings} component={SiteSettings} user={user} hideProgressBars={true} />
        <AuthedRoute path={routePaths.orgSettings} component={OrgSettings} user={user} hideProgressBars={true} />
        <AuthedRoute exact path={routePaths.inventory} component={Inventory} user={user} guard={routeGuards.inventory} />
        <AuthedRoute exact path={routePaths.staging} component={Staging} user={user} guard={routeGuards.inventory} />
        <AuthedRoute exact path={`${routePaths.staging}/:catalogId`} component={CatalogDetail} user={user} guard={routeGuards.inventory} />
        <AuthedRoute
          exact
          path={routePaths.review}
          component={FeatureFlagSwitcher}
          featureFlag={featureFlags.offerShift}
          isOn={OfferReviewV2}
          isOff={OfferReview}
          user={user}
          guard={routeGuards.receiveOffer}
        />
        <AuthedRoute
          exact
          path={`${routePaths.offerReview}/:buyerSiteId/:buyerSiteName`}
          component={OfferLogisticsReview}
          user={user}
          guard={routeGuards.receiveOffer}
        />
        <AuthedRoute
          path={routePaths.offerComparison}
          component={TabSwitcher}
          tabs={[
            { url: '/inventory', title: 'Inventory', component: OfferComparison },
            {
              url: '/trucklane',
              title: 'Truck Lanes',
              component: Trucklane,
              optionalPath: ':trucklaneId?',
            },
          ]}
          user={user}
          guard={routeGuards.receiveOffer}
        />
        <AuthedRoute exact path={routePaths.negotiations} component={Negotiations} user={user} guard={routeGuards.receiveOffer} />
        <AuthedRoute
          exact
          path={`${routePaths.negotiations}/:buyerSiteId/:buyerSiteName`}
          component={NegotiationsDetail}
          user={user}
          guard={routeGuards.receiveOffer}
        />
        <AuthedRoute exact path={routePaths.donationImport} component={BulkActivityImport} user={user} guard={routeGuards.import} />
        <AuthedRoute exact path={routePaths.impersonate} component={SiteList} user={user} />
        <AuthedRoute exact path={routePaths.addSite} component={AddSite} user={user} />
        <AuthedRoute exact path={routePaths.logout} component={Logout} user={user} />
        <AuthedRoute exact path={routePaths.history} component={TransactionHistory} user={user} guard={routeGuards.inventory} />
        <AuthedRoute exact path={routePaths.transactions} component={Transactions} user={user} guard={routeGuards.transactions} />
        <AuthedRoute exact path={routePaths.customers} component={CustomerProfiles} user={user} />
        <AuthedRoute exact path={`${routePaths.customers}/:buyerSiteName`} component={CustomerProfileDetail} user={user} />
        <Route component={NotFoundLayout} />
      </Switch>
    );
  }
}

export default class Routes extends Component {
  render() {
    return (
      <Router>
        <RouteSwitch user={this.props.user} />
      </Router>
    );
  }
}

Routes.propTypes = {
  user: PropTypes.object,
};
