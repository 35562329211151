import React, { useEffect, useState, useRef, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from '@apollo/client';
import { createUseStyles } from 'react-jss';
import pluralize from 'pluralize';
import {
  Theme as theme,
  AlertService,
  Search,
  FilterIcon,
  Button,
  Select,
  SelectBox,
  CheckboxWithLabel,
  OverlayService,
  Flyout,
  Timing,
  SelectOption,
  WandIcon,
  usePolling,
  MiniTooltip,
  Modal,
  Theme,
  DaterangeFilter,
} from '@spoiler-alert/ui-library';
import moment from 'moment';
import { AwardSuggestedOffersByInventory } from '../../graphql/mutations';
import RecommendedNegotiations from '../../components/banners/recommended-negotiations';
import RecommendedNegotiationsV2 from '../../components/banners/recommended-negotiationsV2';
import checkFeature from '../../helpers/check-feature-flag';
import featureFlags from '../../enums/feature-flags';
import FeatureFlag from '../../layouts/feature-flag';
import {
  ActiveListingsQuery,
  PossibleNegotiationsSummary,
  trucklanePollingQuery,
  trucklaneViewQuery,
  AwardSummaryQuery,
} from '../../graphql/queries';
import { TitleService } from '../../services';
import BuyersFlyup from './buyers-flyup';
import { OfferComparisonStrings } from '../../string-resources';
import OfferListings from './offer-listings';
import { logisticsTermEnum, negotiationStatusEnum, offerStatus, truckTypeEnum, trucklaneStatusEnum } from '../../enums';
import OfferComparisonTable from './offer-comparison-table';
import { Breadcrumbs, Store, UserFilters } from '../../store';
import SmartAwardSettings from '../org-settings/smart-award-settings';
import RecalculationBanner from '../trucklane/recalculation-banner';
import trucklaneChannelStatus from '../../enums/trucklane-channel-status';
import client from '../../apollo/client';
import deleteCachedFieldsOnOfferReviewPollingQuery from '../../apollo/cache-helpers/delete-cached-fields-on-offer-review-polling-query';

const useStyles = createUseStyles({
  offerPage: {},
  offerGridHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  offerGridLeftSide: {
    display: 'flex',
    flexDirection: 'row',
  },
  offerGrid: {
    border: '1px #D1D6DA solid',
    borderRadius: 2,
    display: 'flex',
    margin: '25px 0 0',
    flexDirection: 'row',
    minHeight: 'calc(100vh - 98px)',
  },
  offerGridTable: {
    position: 'relative',
    flex: '0 1 100%',
    borderRight: `1px solid ${theme.borderColor}`,
  },
  offerGridDataTable: {},
  offerGridSearchInput: {
    width: 386,
    marginRight: 8,
    marginLeft: '24px',
  },
  filterButton: {
    marginRight: 24,
  },
  pageActions: {
    marginLeft: 'auto',
    display: 'flex',
  },
  filtersHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginBottom: 24,
  },
  offerGridFiltersHeader: {
    margin: 0,
    marginRight: 'auto',
    fontSize: '1rem',
    fontWeight: 500,
  },
  offerGridClearFilters: {
    color: theme.linkTextColor,
    cursor: 'pointer',
    fontSize: '0.875rem',
  },
  tiedCheckbox: {
    fontSize: '0.875rem',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  tiedCheckboxText: {
    marginLeft: 10,
  },
  filterSection: {
    marginBottom: '48px',
  },
  filterSectionTitle: {
    fontSize: '16px',
    fontWeight: 'normal',
    letterSpacing: '-0.18px',
    margin: 0,
  },
  filterSectionDescription: {
    fontSize: '12px',
    borderBottom: `1px solid ${Theme.borderColor}`,
    paddingBottom: 1,
  },
  filterHeader: {
    margin: '24px 0',
    fontWeight: 'normal',
    '& h2': {
      fontSize: '14px',
      fontWeight: 500,
      marginBottom: 0,
    },
  },
  flyoutContainer: {
    height: '100%',
    paddingRight: 0,
  },
  flyout: {
    overflowY: 'auto',
    padding: '24px',
  },
  flyoutContents: {
    padding: 0,
  },
  selectBox: {
    marginRight: '8px',
  },
  alert__title: {
    height: 24,
    fontSize: 16,
    fontWeight: 'bold',
    letterSpacing: 'normal',
    textAlign: 'left',
    marginBottom: '8px',
  },
});

const defaultFilterState = {
  sites: [],
  status: [
    { text: 'Offers Made', value: 'Offers Made' },
    { text: 'Offers Awarded', value: 'Offers Awarded' },
    { text: 'Partially Awarded', value: 'Partially Awarded' },
  ],
  showTied: false,
  belowReservePrice: false,
  suggestedAward: false,
  categories: [],
  businessUnits: [],
  truckTypes: [],
  negotiationStatus: [],
  logistics: [],
  inventoryTypes: [],
  codeDate: { start: undefined, end: undefined },
  trucklaneStatus: [],
  distributionLists: [],
};

const OfferComparison = ({ user, history }) => {
  const classes = useStyles();
  const showNewBanner = checkFeature(featureFlags.reservePriceV2);

  const { data, loading, error, refetch } = useQuery(ActiveListingsQuery);
  const { data: negotiationsData, refetch: refetchNegotiations } = useQuery(PossibleNegotiationsSummary, { fetchPolicy: 'no-cache' });
  const [awardSuggestedOffersByInventory, { loading: bulkAwardSuggestionsLoading }] = useMutation(AwardSuggestedOffersByInventory);
  const showBanner = negotiationsData?.possibleNegotiationsSummaryQuery?.offerListingsToNegotiate.length > 0;

  const [searchText, setSearchText] = useState('');
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [filters, setFilters] = useState({ ...defaultFilterState, ...UserFilters.filters.offerComparison });
  const [activeFilters, setActiveFilters] = useState(filters);
  const [showFilters, setShowFilters] = useState(false);
  const [noDataMessageIndex, setNoDataMessageIndex] = useState(0);
  const [displaySettingsModal, setDisplaySettingsModal] = useState(false);
  const [recalculationRequired, setRecalculationRequired] = useState(false);
  const [recalculating, setRecalculating] = useState(false);
  const [pollingInterval, setPollingInterval] = useState(5000);
  const [checkedInventoryIds, setCheckedInventoryIds] = useState([]);
  const filteredInventoryIds = useRef([]);

  const pollResponse = usePolling({
    idleInterval: pollingInterval,
    poll: true,
    query: trucklanePollingQuery,
    responseKey: 'trucklanePollingQuery',
    parameters: { pollingDetails: { origin: selectedInventory?.siteId, truckType: selectedInventory?.truckType } },
    skip: !selectedInventory || recalculationRequired,
  });

  const pollingRef = useRef();

  const messageInterval = useRef();

  const listingCount = data?.currentUserQuery ? data.currentUserQuery.getListedInventories.length : 0;

  const clearFilters = () =>
    setFilters({
      sites: [],
      status: [],
      categories: [],
      businessUnits: [],
      truckTypes: [],
      negotiationStatus: [],
      logistics: [],
      inventoryTypes: [],
      codeDate: { start: undefined, end: undefined },
      distributionLists: [],
      trucklaneStatus: [],
    });

  const resetPolling = () => {
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'trucklanePollingQuery' });
    setRecalculating(false);
    setRecalculationRequired(false);
    setPollingInterval(5000);
  };

  const handleRowClick = (row) => {
    setSelectedInventory(row);
    resetPolling();
  };

  useEffect(() => {
    if (recalculating) {
      if (pollResponse?.trucklaneChannels[0]?.status === trucklaneChannelStatus.VALID) {
        resetPolling();
      }
    }
    if (!recalculating) {
      if (
        pollResponse?.trucklaneChannels[0]?.status === trucklaneChannelStatus.INVALID ||
        (pollingRef.current?.trucklaneChannels[0]?._id === pollResponse?.trucklaneChannels[0]?._id &&
          pollingRef.current?.trucklaneChannels[0]?.updatedAt !== pollResponse?.trucklaneChannels[0]?.updatedAt)
      ) {
        const pollingLane = pollingRef.current?.trucklaneChannels[0];
        const cacheExists = client.readQuery({ query: trucklaneViewQuery });
        setRecalculationRequired(true);
        if (pollingLane && cacheExists) {
          selectedInventory.offerListings.forEach((offer) => {
            client.cache.modify({
              id: client.cache.identify({
                id: `${pollingLane.truckType}:${offer.logisticsTerm}:${offer.sellerLocationId}:${offer.buyerDestinationId}:${offer.transactionCycleId}`,
                __typename: 'Trucklane',
              }),
              fields: {
                status() {
                  return 'Invalid';
                },
              },
            });
          });
          Store.invalidateTrucklaneChannel(pollingLane);
        }
      } else if (
        pollingRef.current?.trucklaneChannels[0]?._id !== pollResponse?.trucklaneChannels[0]?._id &&
        pollResponse?.trucklaneChannels[0]?.status === trucklaneChannelStatus.VALID
      ) {
        setRecalculationRequired(false);
      }
    }
    pollingRef.current = pollResponse;
  }, [pollResponse]);

  const handleSearch = Timing.debounce((text) => setSearchText(text), 300);

  const handleFilterChange = useCallback(
    (prop, value) => {
      setFilters({
        ...filters,
        [prop]: value,
      });
      setCheckedInventoryIds([]);
    },
    [filters]
  );

  const handleCodeDateRangeChange = useCallback((start, end) => {
    handleFilterChange('codeDate', { start: moment(start), end: moment(end) });
  });

  const removeItem = (array, item) => {
    const index = array.indexOf(item);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  };

  const handleCheckboxFilterChange = useCallback((prop, event) =>
    handleFilterChange(prop, event.checked ? [...filters[prop], event.value] : removeItem(filters[prop], event.value), [handleFilterChange])
  );

  const handleCheckFilterChange = useCallback((event) => handleFilterChange(event.value, event.checked), [handleFilterChange]);

  const toggleFilters = () => {
    if (showFilters) {
      OverlayService.hide();
      setActiveFilters(filters);
      UserFilters.updateFilters('offerComparison', filters);
    } else {
      OverlayService.show();
    }
    setShowFilters(!showFilters);
  };

  const noDataMessageIndexIncrement = () => {
    if (noDataMessageIndex >= OfferComparisonStrings.loadingMessages.length - 1 || listingCount > 0) window.clearInterval(messageInterval.current);
    setNoDataMessageIndex(noDataMessageIndex + 1);
  };

  useEffect(() => {
    messageInterval.current = window.setInterval(noDataMessageIndexIncrement, 2000);
    return () => {
      clearInterval(messageInterval.current);
    };
  }, ['']);

  const noDataMessage = () => {
    if (error) return error.message;
    return loading ? OfferComparisonStrings.loadingMessages[noDataMessageIndex] : OfferComparisonStrings.noData;
  };

  useEffect(() => {
    TitleService.setTitles('Offer Comparison');
    Breadcrumbs.set([
      {
        url: '',
        title: `Offer Comparison ${listingCount > 0 ? `(${listingCount} items)` : ''}`,
      },
    ]);
    if (Store.shouldRefreshOfferComparison) {
      refetch();
      Store.refreshOfferComp(false);
    }
  }, [listingCount]);

  const offerStatusFilterOptions = useMemo(() => {
    return (
      <Select key="status" multiple label="Status" onChange={handleFilterChange.bind(this, 'status')} selectedItems={filters.status}>
        <SelectOption key={offerStatus.noOffers} checkbox value={offerStatus.noOffers}>
          {offerStatus.noOffers}
        </SelectOption>
        <SelectOption key={offerStatus.offersAvailable} checkbox value={offerStatus.offersAvailable}>
          {offerStatus.offersAvailable}
        </SelectOption>
        <SelectOption key={offerStatus.partiallyAwarded} checkbox value={offerStatus.partiallyAwarded}>
          {offerStatus.partiallyAwarded}
        </SelectOption>
        <SelectOption key={offerStatus.offersAwarded} checkbox value={offerStatus.offersAwarded}>
          {offerStatus.offersAwarded}
        </SelectOption>
      </Select>
    );
  }, [offerStatus, handleFilterChange, filters]);

  const categoryFilterOptions = useMemo(() => {
    const selectData = loading
      ? []
      : data.currentUserQuery.inventoryFilterParameters.categories.map((category) => (
          <SelectOption checkbox key={category.id} value={category.id}>
            {category.name}
          </SelectOption>
        ));
    return (
      <Select key="categories" multiple label="Category" onChange={handleFilterChange.bind(this, 'categories')} selectedItems={filters.categories}>
        {selectData}
      </Select>
    );
  }, [data, handleFilterChange, filters]);

  const businessUnitFilterOptions = useMemo(() => {
    const selectData = loading
      ? []
      : data.currentUserQuery.inventoryFilterParameters.businessUnits.map((businessUnit) => (
          <SelectOption checkbox key={businessUnit.id} value={businessUnit.id}>
            {businessUnit.name}
          </SelectOption>
        ));
    return (
      <Select
        key="businessUnits"
        multiple
        label="Business Units"
        onChange={handleFilterChange.bind(this, 'businessUnits')}
        selectedItems={filters.businessUnits}
      >
        {selectData}
      </Select>
    );
  }, [data, filters, handleFilterChange]);

  const inventoryTypeFilterOptions = useMemo(() => {
    const selectData = loading
      ? []
      : data.currentUserQuery.inventoryFilterParameters.inventoryTypes.map((inventoryType) => (
          <SelectOption checkbox key={inventoryType.id} value={inventoryType.id}>
            {inventoryType.name}
          </SelectOption>
        ));
    return (
      <Select
        key="inventoryTypes"
        multiple
        label="Inventory Type"
        onChange={handleFilterChange.bind(this, 'inventoryTypes')}
        selectedItems={filters.inventoryTypes}
      >
        {selectData}
      </Select>
    );
  }, [data, handleFilterChange, filters]);

  const distributionListFilterOptions = useMemo(() => {
    const selectData = loading
      ? []
      : data.currentUserQuery.inventoryFilterParameters.distributionLists.map((distributionList) => (
          <SelectOption checkbox key={distributionList.id} value={distributionList.id}>
            {distributionList.name}
          </SelectOption>
        ));
    return (
      <Select
        key="distributionLists"
        multiple
        label="Distribution Lists"
        onChange={handleFilterChange.bind(this, 'distributionLists')}
        selectedItems={filters.distributionLists}
      >
        {selectData}
      </Select>
    );
  }, [data, filters, handleFilterChange]);

  const truckTypeFilterOptions = useMemo(() => {
    const truckTypes = [truckTypeEnum.dry, truckTypeEnum.refrigerated, truckTypeEnum.frozen];
    return (
      <div key="truckType" className={classes.filterHeader}>
        <h2>Truck Type</h2>
        {truckTypes.map((truckType) => (
          <CheckboxWithLabel
            id={`truckType-${truckType}`}
            label={truckType}
            key={`truckType-${truckType}`}
            value={truckType}
            checked={filters.truckTypes.includes(truckType)}
            onChecked={handleCheckboxFilterChange.bind(this, 'truckTypes')}
          />
        ))}
      </div>
    );
  }, [handleCheckboxFilterChange, filters]);

  const negotiationStatusFilterOptions = useMemo(() => {
    const negotiationStatusTypes = [negotiationStatusEnum.staged, negotiationStatusEnum.published, negotiationStatusEnum.received];
    return (
      <div key="negotiationStatus" className={classes.filterHeader}>
        <h2>Negotiation Status</h2>
        {negotiationStatusTypes.map((negotiationStatusType) => (
          <CheckboxWithLabel
            id={`negotiationStatus-${negotiationStatusType}`}
            label={negotiationStatusType}
            key={`negotiationStatus-${negotiationStatusType}`}
            value={negotiationStatusType}
            checked={filters.negotiationStatus.includes(negotiationStatusType)}
            onChecked={handleCheckboxFilterChange.bind(this, 'negotiationStatus')}
          />
        ))}
      </div>
    );
  }, [handleCheckboxFilterChange, filters]);

  const logisticsFilterOptions = useMemo(() => {
    const logistics = [logisticsTermEnum.pickup, logisticsTermEnum.delivery];
    return (
      <div key="logistics" className={classes.filterHeader}>
        <h2>Logistics</h2>
        {logistics.map((logisticsTerm) => (
          <CheckboxWithLabel
            id={`logistics-${logisticsTerm}`}
            label={logisticsTerm}
            key={`logistics-${logisticsTerm}`}
            value={logisticsTerm}
            checked={filters.logistics.includes(logisticsTerm)}
            onChecked={handleCheckboxFilterChange.bind(this, 'logistics')}
          />
        ))}
      </div>
    );
  }, [handleCheckboxFilterChange, filters]);

  const locationFilterOptions = useMemo(() => {
    const sortedSites = [...user.siteHierarchy].sort((a, b) => {
      const nameA = a.siteName.toUpperCase();
      const nameB = b.siteName.toUpperCase();
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
    return (
      <Select key="sites" label="Location" multiple search onChange={handleFilterChange.bind(this, 'sites')} selectedItems={filters.sites}>
        {sortedSites.map((site) => (
          <SelectOption checkbox key={site._id} value={site._id}>
            {site.siteName}
          </SelectOption>
        ))}
      </Select>
    );
  }, [user, handleFilterChange, filters]);

  const codeDateFilter = useMemo(() => {
    const { start, end } = filters.codeDate || { start: undefined, end: undefined };
    return (
      <DaterangeFilter
        key={'filter-codedate'}
        onChange={handleCodeDateRangeChange}
        position="topright"
        initialValues={{ start, end }}
        label={'Code Date Range'}
      />
    );
  }, [handleCodeDateRangeChange, filters]);

  const trucklaneStatusFilterOptions = useMemo(() => {
    return (
      <div key="trucklaneStatus" className={classes.filterHeader}>
        <h2>Truck Lane Status</h2>
        <CheckboxWithLabel
          id="trucklaneStatus-active"
          label={trucklaneStatusEnum.active}
          value={trucklaneStatusEnum.active}
          checked={filters.trucklaneStatus.includes(trucklaneStatusEnum.active)}
          onChecked={handleCheckboxFilterChange.bind(this, 'trucklaneStatus')}
        />
        <CheckboxWithLabel
          id="trucklaneStatus-inactive"
          label={trucklaneStatusEnum.inactive}
          value={trucklaneStatusEnum.inactive}
          checked={filters.trucklaneStatus.includes(trucklaneStatusEnum.inactive)}
          onChecked={handleCheckboxFilterChange.bind(this, 'trucklaneStatus')}
        />
      </div>
    );
  }, [handleCheckboxFilterChange, filters]);

  const renderedInventoryFilters = useMemo(() => {
    return [
      locationFilterOptions,
      codeDateFilter,
      categoryFilterOptions,
      offerStatusFilterOptions,
      businessUnitFilterOptions,
      inventoryTypeFilterOptions,
      distributionListFilterOptions,
      truckTypeFilterOptions,
    ];
  }, [
    user,
    filters,
    locationFilterOptions,
    codeDateFilter,
    categoryFilterOptions,
    offerStatusFilterOptions,
    businessUnitFilterOptions,
    inventoryTypeFilterOptions,
    distributionListFilterOptions,
    truckTypeFilterOptions,
  ]);

  const renderedOffersFilters = useMemo(() => {
    return [
      <div className={classes.filterHeader} key="isTied">
        <div>
          <CheckboxWithLabel id="showTied" label="Tied" value="showTied" checked={filters.showTied} onChecked={handleCheckFilterChange} />
        </div>
        <div key="belowReservePrice">
          <CheckboxWithLabel
            id="belowReservePrice"
            label="Below Reserve Price"
            value="belowReservePrice"
            checked={filters.belowReservePrice}
            onChecked={handleCheckFilterChange}
          />
        </div>
        <div key="suggestedAward">
          <CheckboxWithLabel
            id="suggestedAward"
            label="Suggested Award"
            value="suggestedAward"
            checked={filters.suggestedAward}
            onChecked={handleCheckFilterChange}
          />
        </div>
      </div>,
      negotiationStatusFilterOptions,
      logisticsFilterOptions,
      trucklaneStatusFilterOptions,
    ];
  }, [filters, handleCheckFilterChange, negotiationStatusFilterOptions, logisticsFilterOptions, trucklaneStatusFilterOptions]);

  const filterCount = () => {
    return Object.keys(activeFilters).filter((fkey) => {
      const filter = activeFilters[fkey];
      if (Array.isArray(filter)) {
        return filter.length > 0;
      }
      if (filter === true) return true;
      if (filter.start !== undefined || filter.end !== undefined) return true;
      return false;
    }).length;
  };

  const displaySmartAwardSettings = () => {
    setDisplaySettingsModal(true);
  };

  const selectedInventoryId = selectedInventory ? selectedInventory._id : '';
  const selectedInventoryDescription = selectedInventory ? selectedInventory.description : '';
  const inventories = data?.currentUserQuery ? data.currentUserQuery.getListedInventories : [];
  const filtersAppliedCount = filterCount();

  const refreshTrucklaneData = () => {
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'trucklanePollingQuery' });
    client.cache.evict({ id: 'ROOT_QUERY', fieldName: 'trucklaneViewQuery' });
    client.cache.gc();
    Store.clearTrucklaneChannels();
    setRecalculationRequired(false);
    setPollingInterval(1000);
    setRecalculating(true);
  };

  const allSelected = () => {
    return (
      filteredInventoryIds.current.length > 0 && checkedInventoryIds.length > 0 && checkedInventoryIds.length === filteredInventoryIds.current.length
    );
  };

  const partialSelected = () => {
    return (
      filteredInventoryIds.current.length > 0 && checkedInventoryIds.length > 0 && checkedInventoryIds.length < filteredInventoryIds.current.length
    );
  };

  const selectAll = (result) => {
    setCheckedInventoryIds(result.checked ? filteredInventoryIds.current : []);
  };

  const handleCheck = (e) => {
    if (checkedInventoryIds.includes(e.value)) {
      setCheckedInventoryIds((prev) => prev.filter((checkedId) => checkedId !== e.value));
    } else {
      setCheckedInventoryIds((prev) => [...prev, e.value]);
    }
  };

  const bulkAwardSuggestions = () => {
    awardSuggestedOffersByInventory({
      variables: {
        inventoryIds: checkedInventoryIds,
        trucklaneGroupChannelId: checkedInventoryIds.includes(selectedInventoryId) ? pollingRef.current?.trucklaneChannels[0]?._id : null,
        trucklaneGroupChannelUpdatedAt: checkedInventoryIds.includes(selectedInventoryId)
          ? pollingRef.current?.trucklaneChannels[0]?.updatedAt
          : null,
      },
      update: (cache) => {
        deleteCachedFieldsOnOfferReviewPollingQuery(cache);
      },
      refetchQueries: [
        {
          query: AwardSummaryQuery,
        },
      ],
    })
      .then((response) => {
        if (!response.data.awardSuggestedOffersByInventory.errors) {
          const message = `${response.data.awardSuggestedOffersByInventory.offerListings.length} suggested ${pluralize(
            'offer',
            response.data.awardSuggestedOffersByInventory.offerListings.length
          )} ${pluralize('has', response.data.awardSuggestedOffersByInventory.offerListings.length)} been successfully awarded`;
          AlertService.alert({ type: 'success', message, autoDismiss: true, dismissDelay: 5000 });
        } else if (response.data.awardSuggestedOffersByInventory.errors.filter((e) => e.code === 'AWARD_OFFER_NO_OFFER_LISTINGS_ERROR').length) {
          AlertService.alert({
            type: 'caution',
            message: (
              <div>
                <div className={classes.alert__title}>No Suggestions Found</div>
                <span>We did not find any suggested awards on the selected inventory. Try filtering for “Suggested Award”</span>
              </div>
            ),
            autoDismiss: true,
            dismissDelay: 5000,
          });
        } else {
          throw Error();
        }
      })
      .catch(() => {
        AlertService.alert({
          type: 'warning',
          message:
            'There was an unexpected error awarding suggestions. Please refresh and try again. If this issue persists contact customer support.',
          autoDismiss: true,
          dismissDelay: 5000,
        });
      })
      .finally(() => {
        setCheckedInventoryIds([]);
      });
  };

  return (
    <div className={classes.offerPage}>
      {showBanner && !showNewBanner && (
        <RecommendedNegotiations negotiationsData={negotiationsData} refetchNegotiations={refetchNegotiations} history={history} />
      )}
      <FeatureFlag featureName={featureFlags.reservePriceV2} showChildrenWhenEnabled={true}>
        <RecommendedNegotiationsV2 history={history} />
      </FeatureFlag>
      <div className={classes.offerGridHeader}>
        <div className={classes.offerGridLeftSide}>
          <SelectBox id="select-all" className={classes.selectBox} partial={partialSelected()} all={allSelected()} onSelect={selectAll} />
          {recalculationRequired ? (
            <MiniTooltip text={<div>Recalculation Required</div>}>
              <Button
                onClick={bulkAwardSuggestions}
                icon={WandIcon}
                disabled={!checkedInventoryIds.length || recalculationRequired || recalculating}
                loading={bulkAwardSuggestionsLoading}
                loadingText="Awarding Suggestions..."
              >
                Award Suggestions
              </Button>
            </MiniTooltip>
          ) : (
            <Button
              className={classes.bulkAwardButton}
              onClick={bulkAwardSuggestions}
              icon={WandIcon}
              disabled={!checkedInventoryIds.length || recalculationRequired || recalculating}
              loading={bulkAwardSuggestionsLoading}
              loadingText="Awarding Suggestions..."
            >
              Award Suggestions
            </Button>
          )}
          <Search onChange={handleSearch} className={classes.offerGridSearchInput} />
          <Button secondary className={classes.filterButton} icon={FilterIcon} onClick={toggleFilters} filter={filtersAppliedCount !== 0}>
            {filtersAppliedCount > 0 ? `${filtersAppliedCount} ${pluralize('Filter', filtersAppliedCount)}` : 'Filter'}
          </Button>
        </div>
        <Button secondary onClick={displaySmartAwardSettings}>
          Smart Award Settings
        </Button>
      </div>
      <div className={classes.offerGrid}>
        <div className={classes.offerGridTable}>
          <div data-testid="TableWrapper" className={classes.offerGridDataTable}>
            <OfferComparisonTable
              user={user}
              loading={loading}
              inventories={inventories}
              selectedInventory={selectedInventory}
              handleRowClick={handleRowClick}
              searchText={searchText}
              filters={activeFilters}
              noDataMessage={noDataMessage()}
              filteredInventoryIds={filteredInventoryIds}
              checkedInventoryIds={checkedInventoryIds || []}
              handleCheck={handleCheck}
            />
          </div>
        </div>
        <OfferListings
          inventoryId={selectedInventoryId}
          description={selectedInventoryDescription}
          availableQuantity={inventories.filter((inv) => inv._id === selectedInventoryId)[0]?.availableQuantity}
          user={user}
          stickyOffset={45}
          showSuggestions={true}
          trucklaneChannel={pollResponse?.trucklaneChannels[0]}
          recalculationRequired={recalculationRequired}
          recalculating={recalculating}
          isBulkAwarding={bulkAwardSuggestionsLoading && checkedInventoryIds.includes(selectedInventoryId)}
        />
      </div>
      {recalculationRequired && <RecalculationBanner recalculateAction={refreshTrucklaneData} />}
      <BuyersFlyup selectedInventoryId={selectedInventoryId} user={user} history={history} />
      <Flyout
        position="right"
        open={showFilters}
        onHide={toggleFilters}
        classes={{ flyout__container: classes.flyoutContainer, flyout__contents: classes.flyoutContents }}
      >
        <div className={classes.flyout}>
          <div className={classes.filtersHeaderContainer} data-element="filters-header">
            <h4 className={classes.offerGridFiltersHeader}>{OfferComparisonStrings.filters}</h4>
            <a className={classes.offerGridClearFilters} tabIndex="1" onClick={clearFilters} data-element="clear-filters">
              {OfferComparisonStrings.clearFilters}
            </a>
          </div>
          <div className={classes.filterSection}>
            <h5 className={classes.filterSectionTitle}>Inventory</h5>
            <div className={classes.filterSectionDescription}>Show inventory that matches...</div>
            {renderedInventoryFilters}
          </div>
          <div className={classes.filterSection}>
            <h5 className={classes.filterSectionTitle}>Offers</h5>
            <div className={classes.filterSectionDescription}>Show inventory with offers that are...</div>
            {renderedOffersFilters}
          </div>
        </div>
      </Flyout>
      {showFilters ? <div id="hideScroll" style={{ display: 'none' }}></div> : <div id="showScroll" style={{ display: 'none' }}></div>}
      <Modal
        onHide={() => {
          setDisplaySettingsModal(false);
        }}
        open={displaySettingsModal}
        closeOnEsc
        closeOnOutsideClick
      >
        <SmartAwardSettings
          user={user}
          cancellable={true}
          onClose={() => {
            setDisplaySettingsModal(false);
          }}
          onSave={() => {
            setDisplaySettingsModal(false);
            setSelectedInventory(null);
          }}
          saveText={'Save and Recalculate'}
          style={{ title: classes.title, wrapper: classes.settingsWrapper }}
        />
      </Modal>
    </div>
  );
};

OfferComparison.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
};

export default OfferComparison;
