import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Theme, Button, UnawardIcon, WandIcon, CalculatorIcon, AlertService, Timing } from '@spoiler-alert/ui-library';
import { createUseStyles } from 'react-jss';
import { useMutation } from '@apollo/client';
import { event } from 'react-fullstory';
import { calculateWeight, calculatePallets, calculateRecovery, calculateNetRevenue, invalidErrorCodes } from './trucklane-utility';
import { offerlistingStatuses } from '../../enums';
import { AwardSuggestedOfferListings, UnawardOfferListings } from '../../graphql/mutations';
import { OfferComparisonStrings } from '../../string-resources';
import { AwardSummaryQuery, trucklanePollingQuery } from '../../graphql/queries';
import { Store } from '../../store';

const styles = {
  row: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 8,
  },
  suggestedSummary: {
    backgroundColor: Theme.green5,
  },
  summary: (selected) => ({
    backgroundColor: selected ? Theme.green5 : Theme.grey5,
  }),
  summaryTable: {
    backgroundColor: Theme.white,
    padding: 12,
    margin: 16,
    border: `1px solid ${Theme.borderColor}`,
    borderRadius: 2,
    width: 450,
    minHeight: 250,
    display: 'flex',
    flexDirection: 'column',
    '& h1': {
      fontSize: '14px',
      fontWeight: '500',
      color: Theme.grey,
    },
  },
  summaryTableRow: {
    display: 'flex',
    padding: '4px',
    borderTop: `1px solid ${Theme.grey10}`,
    '&:first-child': {
      borderTop: 'none',
    },
  },
  summaryTableCell: {
    width: '100px',
  },
  summaryTableHeader: {
    width: '100px',
    color: Theme.grey,
    fontSize: '12px',
    fontWeight: 'normal',
  },
  summaryTableInfo: {
    width: '100px',
    fontSize: '12px',
    fontWeight: '500',
  },
  sumaryTableEmptyInfo: {
    extend: 'summaryTableInfo',
    color: Theme.grey50,
  },
  summaryActions: {
    display: 'flex',
    marginTop: 'auto',
    '& button': {
      width: '100% !important',
      '&:not(:first-child)': {
        marginLeft: 8,
      },
    },
  },
  recalculationBanner: {
    backgroundColor: Theme.orange5,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 4,
    marginBottom: 8,
    padding: '4px 0px',
    '& svg': {
      fill: Theme.orangeDark,
      height: 16,
      width: 16,
      margin: '2px',
    },
    '& h1': {
      fontSize: '14px',
      fontWeight: '500',
      color: Theme.orangeDark,
      margin: 0,
    },
  },
};
const useStyles = createUseStyles(styles);

const TrucklaneCardSummary = ({
  offers,
  suggested,
  recalculation,
  trucklaneId,
  awardSuggestion,
  selected,
  deSelect,
  actionLoading,
  childAction,
  user,
}) => {
  let cache = null;
  const classes = useStyles(selected);
  const tableData = ['WEIGHT', 'PALLETS', 'NET REVENUE', 'RECOVERY'];
  const [tableHeaders, setTableHeaders] = useState([]);
  const awardedOffers = offers.some((offer) => offerlistingStatuses.AWARDED === offer.status);
  const [unawardOffersMutation, { loading: unawardOffersLoading }] = useMutation(UnawardOfferListings);
  const [awardOffersMutation, { loading: awardOffersLoading }] = useMutation(AwardSuggestedOfferListings);

  useEffect(() => {
    if (suggested) {
      setTableHeaders(['CURRENTLY', 'SUGGESTED', 'TOTAL']);
    } else {
      setTableHeaders(['CURRENTLY']);
    }
  }, [offers, recalculation, suggested]);

  const buildTableInfo = (tableRow, tableColumn) => {
    switch (tableRow) {
      case 'WEIGHT':
        return calculateWeight(tableColumn, offers, recalculation);
      case 'PALLETS':
        return calculatePallets(tableColumn, offers, recalculation);
      case 'NET REVENUE':
        return calculateNetRevenue(tableColumn, offers, recalculation);
      case 'RECOVERY':
        return calculateRecovery(tableColumn, offers, recalculation);
      default:
        return '—';
    }
  };

  const trucklaneSummaryInfo = useMemo(() => {
    return tableData.map((info) => (
      <div key={info} className={classes.summaryTableRow}>
        <span className={classes.summaryTableHeader}>{info}</span>
        <span className={classes.summaryTableInfo}>{buildTableInfo(info, 'CURRENTLY')}</span>
        {suggested && (
          <span className={recalculation ? classes.sumaryTableEmptyInfo : classes.summaryTableInfo}>{buildTableInfo(info, 'SUGGESTED')}</span>
        )}
        {suggested && (
          <span className={recalculation ? classes.sumaryTableEmptyInfo : classes.summaryTableInfo}>{buildTableInfo(info, 'TOTAL')}</span>
        )}
      </div>
    ));
  }, [offers]);

  const errorRefresh = () => {
    window.location.reload();
  };

  const unawardOffers = Timing.throttle((e) => {
    e.stopPropagation();
    const offerIds = offers.filter((offer) => offer.status === offerlistingStatuses.AWARDED).map((offer) => offer._id);
    childAction(true);
    deSelect(trucklaneId, !suggested);
    unawardOffersMutation({
      variables: { offerListingIds: offerIds },
      refetchQueries: [
        {
          query: AwardSummaryQuery,
        },
        {
          query: trucklanePollingQuery,
          variables: {
            pollingDetails: [...Store.trucklaneChannels.values()].map((channel) => ({ origin: channel.originDCId, truckType: channel.truckType })),
          },
        },
      ],
    })
      .then((resp) => {
        childAction(false);
        if (resp.data?.unawardOfferListings?.errors?.length) throw Error();
        AlertService.alert({ type: 'success', autoDismiss: true, message: <span>Offers have been unawarded</span> });
        event(`Offers were unawarded in trucklane view`, {
          userId: user._id,
        });
      })
      .catch(() => AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{OfferComparisonStrings.unawardingError}</span> }));
  }, 1000);

  const awardSuggestions = Timing.throttle((e) => {
    e.stopPropagation();
    const offerIds = offers
      .filter((offer) => offer.suggestions?.award?.suggested && offer.status === offerlistingStatuses.ACTIVE)
      .map((offer) => offer._id);
    childAction(true);
    awardOffersMutation({
      variables: { offerListingIds: offerIds },
      update: (cacheResult) => {
        cache = cacheResult;
      },
      refetchQueries: [
        {
          query: AwardSummaryQuery,
        },
        {
          query: trucklanePollingQuery,
          variables: {
            pollingDetails: [...Store.trucklaneChannels.values()].map((channel) => ({ origin: channel.originDCId, truckType: channel.truckType })),
          },
        },
      ],
    })
      .then((resp) => {
        childAction(false);
        if (resp.data?.awardSuggestedOfferListings?.errors?.length) {
          const recalcError = resp.data?.awardSuggestedOfferListings?.errors.some((error) => invalidErrorCodes.includes(error.code));
          if (recalcError) {
            event(`There was a recalculation based error while awarding`, {
              userId: user._id,
            });
            return AlertService.alert({
              type: 'warning',
              autoDismiss: false,
              message: (
                <div>
                  <span>{OfferComparisonStrings.awardingError}</span>
                  <div>
                    <h1 className="error_action" onClick={errorRefresh}>
                      Refresh Suggested Awards
                    </h1>
                  </div>
                </div>
              ),
            });
          }
          throw Error();
        }
        AlertService.alert({ type: 'success', autoDismiss: true, message: <span>Suggested Offers have been awarded</span> });
        awardSuggestion();
        deSelect(trucklaneId, true);
        event(`Offers were awarded with suggestions in trucklane view`, {
          userId: user._id,
        });
        return setTimeout(() => {
          cache.modify({
            id: cache.identify({ id: trucklaneId, __typename: 'Trucklane' }),
            fields: {
              hasSuggestions() {
                return false;
              },
            },
          });
        }, 500);
      })
      .catch(() => AlertService.alert({ type: 'warning', autoDismiss: true, message: <span>{OfferComparisonStrings.awardingError}</span> }));
  }, 1000);

  return (
    <div className={suggested ? classes.suggestedSummary : classes.summary}>
      <div className={classes.summaryTable}>
        <h1>TRUCK LANE SUMMARY</h1>
        <div>
          <div className={classes.summaryTableRow}>
            <span className={classes.summaryTableCell}></span>
            {tableHeaders.map((header) => (
              <span key={header} className={classes.summaryTableHeader}>
                {header}
              </span>
            ))}
          </div>
          {trucklaneSummaryInfo}
        </div>
        {recalculation && (
          <div className={classes.recalculationBanner}>
            <CalculatorIcon /> <h1>Recalculate to view updated suggestions</h1>
          </div>
        )}
        <div className={classes.summaryActions}>
          <Button
            disabled={!awardedOffers || awardOffersLoading || actionLoading}
            warning
            loadingText="Unawarding"
            loading={unawardOffersLoading}
            onClick={(e) => unawardOffers(e)}
            icon={UnawardIcon}
          >
            Unaward All
          </Button>
          {suggested && (
            <Button
              disabled={recalculation || unawardOffersLoading || actionLoading}
              loadingText="Awarding Suggestions"
              loading={awardOffersLoading}
              onClick={(e) => awardSuggestions(e)}
              icon={WandIcon}
            >
              Award Suggestions
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

TrucklaneCardSummary.propTypes = {
  offers: PropTypes.any,
  suggested: PropTypes.bool,
  recalculation: PropTypes.bool,
  trucklaneId: PropTypes.string,
  awardSuggestion: PropTypes.func,
  selected: PropTypes.bool,
  deSelect: PropTypes.func,
  actionLoading: PropTypes.bool,
  childAction: PropTypes.func,
  user: PropTypes.object,
};

export default TrucklaneCardSummary;
