import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { createUseStyles } from 'react-jss';
import { event } from 'react-fullstory';
import { Select, LoadingLarge } from '@spoiler-alert/ui-library';

const useStyles = createUseStyles(
  {
    loadingContainer: {
      height: 'calc(100vh - 75px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    controls: {
      marginBottom: 8,
    },
    select: {
      width: 523,
    },
    iframeWrapper: {
      height: 'calc(100vh - 135px)',
    },
  },
  { name: 'HolisticsDashboard' }
);

const HolisticsDashboardIframe = ({ loading, dashboards, defaultDashboardId, match, handleReportChange, dropdownOptions }) => {
  const classes = useStyles();
  const selectedReportId = match.params.reportId ? match.params.reportId : defaultDashboardId;

  const selectedReport = dashboards.find((d) => d.reportId === selectedReportId);

  useEffect(() => {
    if (selectedReport) {
      event('Viewed Holistics Dashboard', {
        dashboardName: selectedReport.name,
        defaultViewOnPageLoad: selectedReport.reportId === defaultDashboardId,
      });
    }
  }, [selectedReport, defaultDashboardId]);

  const dashboardURL = selectedReport ? selectedReport.embed.url : '';

  const selectedItem = selectedReport ? [{ value: selectedReport.reportId, text: selectedReport.name }] : [];

  return (
    <div>
      {loading || !defaultDashboardId ? (
        <div className={classes.loadingContainer}>
          <LoadingLarge />
        </div>
      ) : (
        <>
          <div className={classes.controls}>
            <Select containerClassName={classes.select} label="Select report" search selectedItems={selectedItem} onChange={handleReportChange}>
              {dropdownOptions}
            </Select>
          </div>
          <div className={classes.iframeWrapper}>
            <iframe src={dashboardURL} id="embedded-iframe" style={{ width: '100%', height: '100%' }} frameBorder="0" allowFullScreen></iframe>
          </div>
        </>
      )}
    </div>
  );
};

HolisticsDashboardIframe.propTypes = {
  dashboards: PropTypes.array,
  defaultDashboardId: PropTypes.string,
  match: PropTypes.object,
  handleReportChange: PropTypes.func,
  dropdownOptions: PropTypes.node,
  loading: PropTypes.bool,
};

export default HolisticsDashboardIframe;
